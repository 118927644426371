import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import cue from '../assets/images/cue.png'
import mix from '../assets/images/mix.png'
import browse from '../assets/images/browse.png'

const HowToUse = props => (
  <Layout>
    <Helmet>
      <title>How to use Mix Rider</title>
      <meta name="description" content="Generic Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>How to use Mix Rider</h1>
          </header>
          <h2>Before Starting</h2>
          <p>
            First make sure you have followed the{' '}
            <a href="/setup">setup instructions</a>
          </p>
          <div className="box">
          <h2>Deck Selection</h2>
          <p>
            The 'A' and 'B' buttons at the top of your device screen are to select which
            is the active deck (left or right).
          </p>
          </div>
          <div className="box">
              <h2>Browse Mode</h2>
              <p>
                Browse mode is used to select the next track to be added to the mix. 
              </p>
              <p>
                Use the arrow (tab) button to switch between track browsing windows.  Rotate
                the dial to move up and down track or folder lists.  To load a track
                into the active deck, tap the dial.  You can't load a track into a playing deck.
              </p>
              <span className="image small">
                <img src={browse} alt="Mix Rider browse mode screen shot" />
              </span>
          </div>
          <div className="box">
              <h2>Cue Mode</h2>
              <p>
                The virtual turntable in cue mode allows you to seek playing
                positions and scratch. 
              </p>
              <p>
                Turn the record to cue the loaded track.
                There are fast seek (&lt;&lt;/&gt;&gt;), and fine seek (&lt;/&gt;) buttons.
                When the track is playing, the fine seek buttons function as nudge buttons to make precise adjustments to the mix.
              </p>
              <p>
                To reset any one of the four hotcue buttons, use a long press.  
              </p>
              <span className="image small">
                <img src={cue} alt="Mix Rider cue mode screen shot" />
              </span>
          </div>
          <div className="box">
            <h2>Mix Mode</h2>
            <p>
              Adjust pitch using the Mix Rider dial which allows for extremely
              precise and responsive tempo adjustments.
            </p>
            <span className="image small">
              <img src={mix} alt="Mix Rider mix mode screen shot" />
            </span>
          </div>
          <p>Happy Mix Riding!</p>
        </div>
      </section>
    </div>
  </Layout>
)

export default HowToUse
